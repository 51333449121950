import { RxssPomsPayload } from '@ts/rxss';
import {
	ERROR_MESSAGES_RXSS_PROGRESSIVE,
	ERROR_MESSAGES_RXSS_ULTRA_THIN,
	ERROR_MESSAGE_NEW_BASE_FRAME,
} from '@utils/constants/rxss';
import { removeEmptyProperties } from '@utils/objects';
import { hasRxErrors } from '@utils/rxss';

type PomsAddOn = 'blue_light' | 'light_responsive' | 'progressive' | 'ultra_thin';

export type RxUploadIssue = {
	field: keyof RxssPomsPayload | 'all';
	message: string;
};

export type RxUploadResponse = {
	/** This will be false if there are any errors or warnings */
	success: boolean;
	errors: RxUploadIssue[];
	warnings: RxUploadIssue[];
	available_addons: PomsAddOn[];
	required_addons: PomsAddOn[];
};

/**
 *  This function is used to send the prescription data to POMS for analysis,
 *  although it does not actually upload the data to the database
 * */
export async function analyzeRxData(params: RxssPomsPayload) {
	if (params.left_pd && !params.right_pd) {
		params.single_pd = params.left_pd;
		delete params.left_pd;
	}

	const urlString = `${process.env.NEXT_PUBLIC_POMS_URL}/api/prescription_data_upload`;

	const wrappedParams = { prescription_data: { ...removeEmptyProperties(params) } };

	// We need to convert the wrapped params object into a query string
	// https://stackoverflow.com/a/56174085

	// This function is used to recursively flatten the object into an array of [breadcrumbs, value] pairs,
	// Where "breadcrumbs" is an array of keys that lead to the value.
	// Something like [['prescription_data', 'left_sphere'], -1.25] would be a realistic pair.
	const getPairs = (obj: object, keys = []) => {
		return Object.entries(obj).reduce((pairs, [key, value]) => {
			if (typeof value === 'object') {
				pairs.push(...getPairs(value, [...keys, key]));
			} else {
				pairs.push([[...keys, key], value]);
			}
			return pairs;
		}, []);
	};

	// Now we can convert the array of breadcrumb-value pairs into a query string
	// with the format: prescription_data[param]=value
	const queryString = getPairs(wrappedParams)
		.map(([[key0, ...keysRest], value]) => `${key0}${keysRest.map(key => `[${key}]`).join('')}=${encodeURIComponent(value)}`)
		.join('&');

	const url = new URL(`${urlString}?${queryString}`);

	try {
		const res = await fetch(url.toString(), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'X-API-KEY': process.env.NEXT_PUBLIC_POMS_API_KEY,
				'accept': 'application/json',
			},
		});
		const data: RxUploadResponse = await res.json();

		return data;
	} catch (error) {
		throw `Error sending prescription info to POMS: ${error}`;
	}
}

/**
 * This function is used to upload the prescription data for a specific bundle to the POMS database
 * */
export async function uploadRxData(params: {
	shopify_bundle_id: string;
	orderNumber: string;
	rx_data: RxssPomsPayload;
	errors: RxUploadIssue[];
}) {
	let rx_method = 'rxss-success';
	const hasProgressiveError = hasRxErrors(params.errors, ERROR_MESSAGES_RXSS_PROGRESSIVE);
	const hasUltraThinError = hasRxErrors(params.errors, ERROR_MESSAGES_RXSS_ULTRA_THIN);
	const hasNewBaseError = hasRxErrors(params.errors, ERROR_MESSAGE_NEW_BASE_FRAME);
	if (hasProgressiveError) {
		rx_method = 'rxss-progressive-opt-out';
	} else if (hasUltraThinError) {
		rx_method = 'rxss-needs-ultrathin';
	} else if (hasNewBaseError) {
		rx_method = 'rxss-new-base-frame-required';
	}

	const payload = {
		order_number: params.orderNumber,
		shopify_bundle_id: params.shopify_bundle_id,
		rx_method: rx_method,
		prescription_data: removeEmptyProperties(params.rx_data),
	};

	try {
		const res = await fetch(`${process.env.NEXT_PUBLIC_POMS_URL}/api/prescription_data_upload/create`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-API-KEY': process.env.NEXT_PUBLIC_POMS_API_KEY,
				'accept': 'application/json',
			},
			body: JSON.stringify(payload),
		});
		const data: Pick<RxUploadResponse, 'success' | 'warnings'> = await res.json();
		return data;
	} catch (error) {
		throw `Error sending prescription info to POMS: ${error}`;
	}
}
