import { COURIER_OPTIONS } from '@constants';
import { Body, CanadaPost, DHL, FedEx, LabelText, Landmark, Paragraph, USPS, UPS } from '@components';
import styles from './TrackingNumber.module.scss';

type TrackingNumberProps = {
	company: string;
	number: string;
	url: string;
};

function determineCourierIcon(company) {
	const casedCompany = company.toUpperCase();

	if (casedCompany.includes(COURIER_OPTIONS.DHL)) return <DHL />;
	if (casedCompany.includes(COURIER_OPTIONS.UPS)) return <UPS />;
	if (casedCompany.includes(COURIER_OPTIONS.USPS)) return <USPS />;
	if (casedCompany.includes(COURIER_OPTIONS.FEDEX)) return <FedEx />;
	if (casedCompany.includes(COURIER_OPTIONS.LANDMARK)) return <Landmark />;
	if (casedCompany.includes(COURIER_OPTIONS.CANADA_POST)) return <CanadaPost />;
	return company;
}

const TrackingNumber = ({ company, number, url }: TrackingNumberProps) => {
	return (
		<div className={styles.container} data-status={company ? 'active' : 'cancelled'}>
			{company && number ? (
				<>
					<div className={styles.icon}>{determineCourierIcon(company)}</div>
					<LabelText style={{ marginBottom: '0.4rem' }}>Tracking Number</LabelText>
					<a className={styles.number} href={url} target='_blank' rel='noopener noreferrer'>
						<Body>{number}</Body>
					</a>
				</>
			) : (
				<button
					type='button'
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					onClick={() => window.adaEmbed && window?.adaEmbed?.toggle()}
					className={styles.contact}
					title='Opens ADA chatbot for immediate customer service'
				>
					<Paragraph>Contact Us</Paragraph>
				</button>
			)}
		</div>
	);
};

export default TrackingNumber;
