import React, { useCallback } from 'react';
import { Button, Flex, Link, Loading, Modal, Spacer } from '@components/common';
import { Heading, Paragraph } from '@components/typography';
import { UPSELL_STATE, useOrderContext } from '@utils/context/Order';
import { slideFromBottom } from '@utils/motions';
import { PAIR_CARE_MODAL_BUTTON_COPY, PAIR_CARE_MODAL_COPY } from '@utils/constants/account';
import styles from './AddPairCareModal.module.scss';

const AddPairCareModal = () => {
	const { addVariantToOrder, isMutating, pairCareUpsellState, order, pairCareVariant } = useOrderContext();

	const attemptOrderEdit = useCallback(async () => {
		await addVariantToOrder({
			orderId: order.shopify_id,
			variantId: pairCareVariant.id,
		});
	}, [addVariantToOrder, order.shopify_id, pairCareVariant.id]);

	return (
		<Modal>
			<Modal.Content className={styles.container} customAnimation={slideFromBottom}>
				<Flex column fullHeight justify='between'>
					<Flex column align='start' gap={2}>
						<Heading tag='h5'>
							{pairCareUpsellState === UPSELL_STATE.PENDING ? 'Thanks for adding PairCare!' : 'PairCare'}
						</Heading>
						{isMutating ? (
							<Loading />
						) : (
							<>
								<Paragraph>{PAIR_CARE_MODAL_COPY[pairCareUpsellState].LINE_1}</Paragraph>
								<Spacer size={'1.6rem'} />
								<Paragraph>
									{PAIR_CARE_MODAL_COPY[pairCareUpsellState].LINE_2}
									{pairCareUpsellState === UPSELL_STATE.PENDING && (
										<Link small href='https://paireyewearhelp.zendesk.com/hc/en-us/requests/new'>
											contact our customer service team.
										</Link>
									)}
								</Paragraph>
							</>
						)}
					</Flex>
					<Flex fullWidth gap={3}>
						<Modal.Close asChild>
							<Button
								fullWidth
								color='white'
								label={pairCareUpsellState === UPSELL_STATE.PENDING ? 'Sent!' : 'Close'}
								disabled={pairCareUpsellState === UPSELL_STATE.PENDING}
							/>
						</Modal.Close>
						{pairCareUpsellState === UPSELL_STATE.AVAILABLE && (
							<Button
								onClick={() => attemptOrderEdit()}
								fullWidth
								label='+Add'
								withPrice
								price={pairCareVariant.price.amount}
								data-add-paircare-upsell
							/>
						)}
					</Flex>
				</Flex>
			</Modal.Content>
			<Modal.Trigger
				asChild
				disabled={
					isMutating ||
					pairCareUpsellState === UPSELL_STATE.INELIGABLE ||
					pairCareUpsellState === UPSELL_STATE.PURCHASED
				}
			>
				<Button
					extraClasses={styles.button}
					label={PAIR_CARE_MODAL_BUTTON_COPY[pairCareUpsellState]}
					data-open-paircare-modal={pairCareUpsellState === UPSELL_STATE.PENDING ? 'pending' : 'available'}
					color={pairCareUpsellState === UPSELL_STATE.PENDING ? 'white' : 'green'}
				/>
			</Modal.Trigger>
		</Modal>
	);
};

export default AddPairCareModal;
