import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const DHL = () => {
	return (
		<AccessibleIcon.Root label={'DHL Icon'}>
			<svg viewBox='0 0 46.986 29.979'>
				<path fill='#ffcb01' d='M0 0h46.986v29.979H0z' />
				<g fill='#d80613'>
					<path d='M8.731 11.413L7.276 13.39h7.93c.401 0 .396.151.2.418-.199.27-.532.737-.735 1.012-.103.139-.289.392.327.392h3.243l.961-1.306c.596-.809.052-2.492-2.079-2.492l-8.392-.001z' />
					<path d='M6.687 17.854l2.923-3.972h3.627c.401 0 .396.152.2.418l-.74 1.008c-.103.139-.289.392.327.392h4.858c-.403.554-1.715 2.154-4.067 2.154H6.687zM23.425 15.699l-1.585 2.155h-4.181l1.585-2.155zM29.829 15.211H19.604l2.796-3.798h4.179l-1.602 2.178h1.865l1.604-2.178h4.179zM29.47 15.699l-1.585 2.155h-4.179l1.585-2.155zM.722 16.549H6.88l-.336.457H.722zM.722 15.699h6.784l-.337.457H.722zM.722 17.399h5.533l-.335.455H.722zM46.265 17.006h-6.136l.337-.457h5.799zM46.265 17.854h-6.759l.334-.455h6.425zM41.091 15.699h5.174v.458h-5.51zM38.413 11.413l-2.796 3.798h-4.429l2.798-3.798zM30.83 15.699s-.305.418-.454.618c-.524.71-.061 1.536 1.652 1.536h6.712l1.585-2.154H30.83z' />
				</g>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default DHL;
