import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import variables from '@styles/export.module.scss';
import { Caption, Paragraph } from '@components/typography';
import { Accordian, AddPairCareModal, Checkmark, Flex, InformationIcon, PairCareBody, RichMedia, Spacer } from '@components';
import { UPSELL_STATE, useOrderContext } from '@utils/context/Order';
import { useIsMobile } from '@utils/hooks';
import { PAIR_CARE_UPSELL_COPY } from '@utils/constants/account';
import styles from './PairCareUpsell.module.scss';

const PairCareUpsell = () => {
	const { exactlyOneBase, pairCareVariant, pairCareUpsellState, baseFrameLineItem } = useOrderContext();
	const isMobile = useIsMobile();
	const accordionIsDefaultOpen = pairCareUpsellState === UPSELL_STATE.AVAILABLE || pairCareUpsellState === UPSELL_STATE.PENDING;
	const [isOpen, setIsOpen] = useState(accordionIsDefaultOpen);

	/**
	 * 	TODO: This effect makes sure that the default accordion state is accurate, but it should be removed.
	 *	Deriving the upsell state requires an async fetch, so ideally we'd block the render until that's finished,
	 *  but right now we can't do that without breaking the funky height calculations (see the TODO in context/Order.tsx)
	 * */
	useEffect(() => {
		const accordionIsOpen = pairCareUpsellState === UPSELL_STATE.AVAILABLE || pairCareUpsellState === UPSELL_STATE.PENDING;
		setIsOpen(accordionIsOpen);
	}, [pairCareUpsellState]);

	// Don't render anything unless there's exactly one Base Frame
	if (!exactlyOneBase) {
		return null;
	}

	const notificationClasses = cn(styles.notification, {
		[styles['notification--purchased']]: pairCareUpsellState === UPSELL_STATE.PURCHASED,
		[styles['notification--ineligable']]: pairCareUpsellState === UPSELL_STATE.INELIGABLE,
	});

	return (
		<div className={styles.container}>
			<Accordian
				title='Protect Your Base Frame'
				isOpen={isOpen || !isMobile}
				onClick={() => setIsOpen(prev => !prev || !isMobile)}
				className={cn(styles.accordionHeader, { [styles['accordionHeader--desktop']]: !isMobile })}
				customRow={cn(styles.accordionRow, { [styles['accordionRow--desktop']]: !isMobile })}
				iconStyle='caret'
			>
				<Spacer size={'1.6rem'} />
				<div className={styles.upsellContainer}>
					<Flex className={notificationClasses} gap={3}>
						{pairCareUpsellState === UPSELL_STATE.PURCHASED ? (
							<Checkmark className={styles.check} color={variables.green2} />
						) : (
							<InformationIcon
								className={styles.info}
								width={28}
								height={24}
								color={pairCareUpsellState === UPSELL_STATE.INELIGABLE ? variables.orange3 : variables.blue3}
							/>
						)}
						<Paragraph>{PAIR_CARE_UPSELL_COPY[pairCareUpsellState]}</Paragraph>
					</Flex>
					<Flex column pad={4} gap={3} className={styles.upsell}>
						<Caption>{baseFrameLineItem.product_title}</Caption>
						<Flex align='center' className={styles.upsellActions}>
							{pairCareVariant && (
								<>
									<Flex className={styles.pairCareCard}>
										<RichMedia
											containerType={'line-item'}
											src={baseFrameLineItem.image.src}
											title={baseFrameLineItem.image.alt}
											aspectRatio={'4 / 3'}
										/>
										<div>
											<PairCareBody variant={pairCareVariant} />
										</div>
									</Flex>
									<AddPairCareModal />
								</>
							)}
						</Flex>
					</Flex>
				</div>
			</Accordian>
		</div>
	);
};

export default PairCareUpsell;
