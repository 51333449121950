import { createContext, useContext, useState } from 'react';
import { RXContextProps, RXProviderProps } from '@ts/contexts';
import { RXSS_STEPS } from '@utils/constants/rxss';

const RXSSContext = createContext<RXContextProps>(null);

export const RXSSProvider = ({
	children,
	baseFrameImage = null,
	orderNumber = null,
	productId = null,
	bundleId = null,
}: RXProviderProps) => {
	const [formData, setFormData] = useState<RXContextProps['formData']>({});
	const [hasTwoPds, setHasTwoPds] = useState<RXContextProps['hasTwoPds']>(false);
	const [isPdFieldSelected, setIsPdFieldSelected] = useState<RXContextProps['isPdFieldSelected']>(false);
	const [isNextStepBlocked, setIsNextStepBlocked] = useState<boolean>(false);
	const [selectedFields, setSelectedFields] = useState<RXContextProps['selectedFields']>([]);
	const [baseFrameId, setBaseFrameId] = useState<RXContextProps['baseFrameId']>(null);
	const [activeInput, setActiveInput] = useState<RXContextProps['activeInput']>(null);
	const [togglableInputs, setTogglableInputs] = useState<RXContextProps['togglableInputs']>(
		{} as RXContextProps['togglableInputs']
	);
	const [isPdModalOpen, setIsPdModalOpen] = useState<RXContextProps['isPdModalOpen']>(false);
	const [isPickerOpen, setIsPickerOpen] = useState<RXContextProps['isPickerOpen']>(false);
	const [pdToolInit, setPdToolInit] = useState<RXContextProps['pdToolInit']>(false);
	const [pdToolHasError, setPdToolHasError] = useState<RXContextProps['pdToolHasError']>(false);
	const [step, setStep] = useState<RXContextProps['step']>(RXSS_STEPS.FIELDS);
	const [uploadResult, setUploadResult] = useState<RXContextProps['uploadResult']>(null);

	return (
		<RXSSContext.Provider
			value={{
				formData,
				setFormData,
				hasTwoPds,
				setHasTwoPds,
				isPdFieldSelected,
				setIsPdFieldSelected,
				selectedFields,
				setSelectedFields,
				baseFrameId,
				setBaseFrameId,
				activeInput,
				setActiveInput,
				togglableInputs,
				setTogglableInputs,
				isPdModalOpen,
				setIsPdModalOpen,
				isPickerOpen,
				setIsPickerOpen,
				pdToolInit,
				setPdToolInit,
				pdToolHasError,
				setPdToolHasError,
				step,
				setStep,
				baseFrameImage,
				isNextStepBlocked,
				setIsNextStepBlocked,
				uploadResult,
				setUploadResult,
				orderNumber,
				productId,
				bundleId,
			}}
		>
			{children}
		</RXSSContext.Provider>
	);
};

export function useRXSSContext() {
	return useContext(RXSSContext);
}
