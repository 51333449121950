import React from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Upload = () => {
	return (
		<AccessibleIcon.Root label='Upload Icon'>
			<svg width='20' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g id='Group 25'>
					<path id='Vector' d='M7.5 5L10.5 2L13.5 5' stroke='#080593' strokeWidth='2' strokeLinecap='square' />
					<path
						id='Vector_2'
						d='M10.5 2.75V10'
						stroke='#080593'
						strokeWidth='2'
						strokeLinecap='square'
						strokeLinejoin='round'
					/>
					<path
						id='Vector_3'
						d='M1.5 6.5L1.5 15C1.5 16.1046 2.61157 17 3.98276 17H17.0172C18.3884 17 19.5 16.1046 19.5 15V6.5'
						stroke='#080593'
						strokeWidth='2'
						strokeLinecap='square'
					/>
				</g>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Upload;
