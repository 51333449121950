import { Order } from '@ts/shopify-admin-api';

export default async function getOrderByOrderNumber(orderNumber: string, email?: string) {
	const order = await fetch('/api/order', {
		method: 'POST',
		body: JSON.stringify({
			orderNumber,
			email,
		}),
	});

	const response = await order.json();
	return response.orders.nodes as Array<Order>;
}
