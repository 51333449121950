import { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import useEditOrder, { EditOrderArgs } from '@services/shopify/hooks/useEditOrder';
import { EditOrderResponse } from '@ts/accounts';
import { PRODUCT_TYPES } from '@constants';
import { LineItemPOMS, OrderDetailPOMS, OrderPOMS } from '@ts/poms/orders';
import { getDaysUntil } from '@utils/dates';
import { getOrderByOrderNumber, useProductQuery } from '@services/shopify';
import { OrderActionType, OrderTransactionKind, OrderTransactionStatus } from '@ts/shopify-admin-api';
import { NormalizedProduct, NormalizedVariant } from '@ts/index';
import OrderDetailsSkeletonLoader from '@components/account/DetailsSkeletonLoader';

export enum UPSELL_STATE {
	INELIGABLE,
	AVAILABLE,
	PENDING,
	PURCHASED,
}

type OrderContextProps = {
	isMutating: boolean;
	mutationSuceeded: boolean;
	mutationFailed: boolean;
	addVariantToOrder: (args: EditOrderArgs) => Promise<EditOrderResponse>;
	pairCareUpsellState: UPSELL_STATE;
	exactlyOneBase: boolean;
	pairCareVariant: NormalizedVariant;
	baseFrameLineItem: LineItemPOMS;
	order: OrderDetailPOMS;
	totalOutstanding: number;
};

type OrderProviderProps = {
	children: React.ReactNode;
	order: OrderDetailPOMS;
};

export const checkForUnpaidPairCare = async (order: OrderPOMS) => {
	const pairCareLine = order.line_items.find(line => !line.removed && line.product_title.toLocaleLowerCase() === 'paircare');

	if (!pairCareLine) {
		return {
			totalOutstanding: 0, // this might not actually be true
			hasUnpaidPairCare: false,
		};
	}

	const pairCareVariantPrice = parseFloat(pairCareLine.price.substring(1));

	const name = order.order_number;

	const [res] = await getOrderByOrderNumber(name);

	const { totalOutstandingSet, agreements, transactions } = res;

	const pyrusOrderEdit = agreements.nodes.find(
		a => a.reason === OrderActionType.OrderEdit && a.app?.title === 'Headless Storefront - 2024'
	);

	const totalOutstanding = parseFloat(totalOutstandingSet?.shopMoney?.amount ?? '0');

	/**
	 *  We can honor PairCare as long as there's at least one transaction on an order that:
	 * 		- happened after this app triggered an order edit
	 * 		- is a "sale" transaction for a value at least as much as the PairCare variant
	 */
	const recordOfInvoicePaid = transactions.find(transaction => {
		const { kind, status, amountSet, createdAt } = transaction;
		const { shopMoney } = amountSet;

		const transactionAmount = shopMoney.amount;
		const isSuccessfulSale = kind === OrderTransactionKind.Sale && status === OrderTransactionStatus.Success;
		const happenedAfterUpsellEdit = !!pyrusOrderEdit && createdAt > pyrusOrderEdit.happenedAt;

		return isSuccessfulSale && transactionAmount >= pairCareVariantPrice && happenedAfterUpsellEdit;
	});

	const hasUnpaidPairCare = totalOutstanding > 0 && totalOutstanding >= pairCareVariantPrice && !recordOfInvoicePaid;

	return {
		totalOutstanding,
		hasUnpaidPairCare,
	};
};

const OrderContext = createContext<OrderContextProps>(null);

export const OrderProvider = ({ children, order }: OrderProviderProps) => {
	const { date: createdAt, line_items: lineItems } = order;
	let upsellState: UPSELL_STATE;

	const { data: pairCare, isLoading, isError } = useProductQuery('pair-care');

	// Check qualities of order
	const { exactlyOneBase, baseFrameLineItem, hasPairCare } = lineItems.reduce(
		(result, current) => {
			if (current.product_title.toLocaleLowerCase() === 'paircare' && !current.removed) {
				result.hasPairCare = true;
			}

			if (current.product_type.includes(PRODUCT_TYPES.BASE_FRAME)) {
				result.numberOfBases = result.numberOfBases + 1;
				result.baseFrameLineItem = current;
			}

			result.exactlyOneBase = result.numberOfBases === 1;

			return result;
		},
		{
			numberOfBases: 0,
			exactlyOneBase: false,
			baseFrameLineItem: null as LineItemPOMS,
			hasPairCare: false,
		}
	);

	// Get Pair Care Plan for the order
	const pairCareVariant =
		pairCare && exactlyOneBase
			? (pairCare as NormalizedProduct).variants.find(variant => {
					return (
						parseFloat(variant.metafields.minThreshold) <= parseFloat(baseFrameLineItem.price) &&
						parseFloat(variant.metafields.maxThreshold) >= parseFloat(baseFrameLineItem.price)
					);
				})
			: null;

	// TODO: see if we can get this from POMS so we're not fetching order stuff twice
	const {
		data: extraInfo,
		isLoading: fetchingOrder,
		isError: errorFetchingOrder,
	} = useQuery(
		['extra-order-info', order.order_number],
		async () => {
			return await checkForUnpaidPairCare(order);
		},
		{
			enabled: !!pairCareVariant,
		}
	);

	const { totalOutstanding = 0, hasUnpaidPairCare } = extraInfo ?? {};

	const {
		mutateAsync: attemptOrderEdit,
		isSuccess: mutationSuceeded,
		isError: mutationFailed,
		isLoading: isMutating,
	} = useEditOrder();

	// TODO: this breaks the height calc. we should prob just remove the animation
	// if (exactlyOneBase && (isLoading || fetchingOrder)) {
	// 	return <OrderDetailsSkeletonLoader />;
	// }

	const addVariantToOrder = async ({ orderId, variantId }: EditOrderArgs) => {
		const result = await attemptOrderEdit({
			orderId,
			variantId,
		});
		return result;
	};

	// Check days since creation
	const moreThan30Days = getDaysUntil(new Date(createdAt), new Date()) > 30;

	/**
	 *
	 * 	There's no way to tell which items are contributing to the outstanding balance.
	 *
	 * 	But we can get the total outstanding balance: `order.totalOutstandingSet`.
	 * 	We can also get a chronological list of sales agreements associated with an order,
	 *  and we can filter for agreements of type "ORDER_EDIT" that came from this app.
	 *  We can also get a list of transactions associated with an order,
	 * 	and we can determine which of the transactions are Invoice Payments.
	 *
	 * 	So, with the logic below, we can almost always figure out if a PairCare product is "Pending",
	 * 	as long as that's the only product we expect to be added via website-triggered order edit.
	 *
	 *
	 *  This works for:
	 *  Order Placed -> Add Upsell -> Pay Invoice
	 *  Order Placed With Pair Care -> Admin Edit -> Pay Invoice
	 *  Order Placed -> Admin Edit -> Add Upsell -> Pay Invoice
	 *  Order Placed -> Add Upsell -> Pay Invoice -> Admin Edit
	 *  Order Placed -> Add Upsell -> Admin Edit -> Pay Invoice
	 *
	 *  */
	if (hasUnpaidPairCare || mutationSuceeded) {
		upsellState = UPSELL_STATE.PENDING;
	} else if (hasPairCare) {
		upsellState = UPSELL_STATE.PURCHASED;
	} else if (moreThan30Days) {
		upsellState = UPSELL_STATE.INELIGABLE;
	} else {
		upsellState = UPSELL_STATE.AVAILABLE;
	}

	return (
		<OrderContext.Provider
			value={{
				isMutating,
				mutationSuceeded,
				mutationFailed,
				addVariantToOrder,
				pairCareUpsellState: upsellState,
				exactlyOneBase,
				pairCareVariant,
				baseFrameLineItem,
				order,
				totalOutstanding: upsellState === UPSELL_STATE.PENDING ? totalOutstanding : 0,
			}}
		>
			{children}
		</OrderContext.Provider>
	);
};

export const useOrderContext = () => useContext(OrderContext);
