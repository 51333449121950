import { useQuery } from '@tanstack/react-query';
import { getOrderDetails } from '@services/poms/operations/get-order-details';
import { normalizeOrderDetails } from '@utils/normalizers';

type useOrderDetails = { id: string | string; locale?: string };

const useOrderDetails = ({ id }: useOrderDetails) => {
	return useQuery(
		['order-details', id],
		async () => {
			const data = await getOrderDetails({ id });
			return normalizeOrderDetails(data);
		},
		{ enabled: !!id, refetchOnMount: false, refetchOnWindowFocus: false, retry: false }
	);
};
export default useOrderDetails;
