export const getDaysUntil = (startDate: Date, endDate: Date) => {
	return Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
};

export const formatDateLocale = (date, locale = 'en', options = null) => {
	const formatOpts = options || {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	};
	return new Intl.DateTimeFormat(locale, formatOpts).format(new Date(date));
};
