import { memo } from 'react';
import variables from '@styles/export.module.scss';

type StatusGaugeProps = {
	status: number;
	fillColor: string;
	nodes: number;
	horizontal?: boolean;
};

function determineHeight(key) {
	switch (key) {
		case 1:
			return 27;
		case 2:
			return 122;
		case 3:
			return 217;
		case 4:
			return 312;
		case 5:
			return 407;
		default:
			return 27;
	}
}

const StatusGauge = ({ status = 1, nodes = 5, fillColor = variables.green1, horizontal = false }: StatusGaugeProps) => {
	const height = determineHeight(nodes);
	const filledInHeight = determineHeight(status);

	/**
	 * Generates the array of objects with data we use to map out the bullet points in the SVG
	 * {nodes} determines the number of rects we generate. Mainly used for when there is no prescription.
	 */
	function generateRectsArray() {
		const placeholder = null;
		return Array.apply(placeholder, new Array(nodes)).reduce(function reduceRects(rects, current, index) {
			const currentRect = {
				index,
				xposition: horizontal ? 1 : 0,
				yposition: !horizontal ? 1 : 0,
				fill: status >= index + 1 ? fillColor : variables.gray2,
			};

			if (rects.length) {
				currentRect.xposition = rects[index - 1].xposition + 94.75;
				if (!horizontal) currentRect.yposition = rects[index - 1].yposition + 94.75;
				if (horizontal) currentRect.xposition = rects[index - 1].xposition + 94.75;
			}

			return [...rects, currentRect];
		}, []);
	}

	function mapRects(rects) {
		return rects.map(function rectMapFn(rect, i) {
			return (
				<circle
					key={horizontal ? rect.xposition : rect.yposition}
					cx={horizontal ? rect.xposition + 8 : 8}
					cy={horizontal ? 8 : rect.yposition + 8}
					r={8}
					fill={rect.fill}
					stroke={variables.gray2}
					strokeWidth='2'
					data-node={i + 1}
				/>
			);
		});
	}

	return (
		<svg
			width={horizontal ? height + 4 : '20'}
			height={horizontal ? '20' : height + 4}
			viewBox={horizontal ? `0 0 ${height + 4} 16` : `0 0 16 ${height + 4}`}
			fill='none'
		>
			{horizontal ? (
				<rect y='4' height='8' width={height - 16} rx='4' fill={variables.gray2} />
			) : (
				<rect x='4' width='8' height={height - 16} rx='4' fill={variables.gray2} />
			)}
			{mapRects(generateRectsArray())}
			{horizontal ? (
				<rect y='6' x='2' height='4' width={filledInHeight - 16} rx='2' fill={fillColor} />
			) : (
				<rect x='6' y='2' width='4' height={filledInHeight - 16} rx='2' fill={fillColor} />
			)}
		</svg>
	);
};

export default memo(StatusGauge);
