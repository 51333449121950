import { getAsyncError } from '@services/handle-fetch-response';

export const getOrderInvoice = async (customerId, orderNumber) => {
	try {
		const res = await fetch(
			`${process.env.NEXT_PUBLIC_POMS_URL}/api/v2/customer/${customerId}/order/${orderNumber}/invoice/pdf`,
			{
				method: 'GET',
				headers: {
					'X-API-KEY': process.env.NEXT_PUBLIC_POMS_API_KEY,
					'Content-Type': 'application/pdf',
				},
			}
		);

		if (res.ok) {
			const blob = await res.blob();
			return new Blob([blob], { type: 'application/pdf' });
		}

		throw await getAsyncError(res);
	} catch (error) {
		throw error;
	}
};
