import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Landmark = () => {
	return (
		<AccessibleIcon.Root label={'Landmark Global Icon'}>
			<svg viewBox='0 0 285 99' fill='none'>
				<g clipPath='url(#odfzuwlmom-clip0_106571_960)'>
					<path
						d='M276.426 94.2469C276.426 95.1101 276.133 95.4725 275.451 95.4725C274.769 95.4725 274.475 95.1101 274.475 94.2469V90.8896H272.797V94.3641C272.797 95.9628 273.447 96.7301 274.785 96.7301C275.091 96.7391 275.396 96.6808 275.678 96.5593C275.96 96.4378 276.211 96.256 276.415 96.0267V96.6768H278.094V90.8896H276.415L276.426 94.2469Z'
						fill='#5A5A5F'
					/>
					<path
						d='M230.752 90.8365C230.4 90.8229 230.049 90.8902 229.726 91.0334C229.404 91.1766 229.119 91.3918 228.892 91.6625V88.998H227.134V96.6717H228.892V95.899C229.119 96.1697 229.404 96.3849 229.726 96.528C230.049 96.6712 230.4 96.7386 230.752 96.7249C232.18 96.7249 233.305 95.7284 233.305 93.7781C233.305 91.9449 232.18 90.8365 230.752 90.8365ZM230.219 95.3501C229.367 95.3501 228.892 94.6893 228.892 93.7834C228.892 92.8775 229.367 92.2167 230.219 92.2167C231.072 92.2167 231.546 92.8668 231.546 93.7834C231.546 94.7 231.067 95.3501 230.219 95.3501Z'
						fill='#5A5A5F'
					/>
					<path
						d='M237.435 90.8365C237.082 90.8236 236.732 90.8914 236.409 91.0345C236.087 91.1776 235.802 91.3924 235.575 91.6625V90.8898H233.816V98.5581H235.58V95.8936C235.807 96.1637 236.092 96.3785 236.415 96.5216C236.737 96.6647 237.088 96.7325 237.44 96.7196C238.874 96.7196 239.993 95.6112 239.993 93.7727C239.987 91.833 238.868 90.8365 237.435 90.8365ZM236.902 95.3501C236.055 95.3501 235.575 94.6999 235.575 93.7834C235.575 92.8668 236.055 92.2167 236.902 92.2167C237.749 92.2167 238.229 92.8775 238.229 93.7834C238.229 94.6893 237.754 95.3501 236.902 95.3501Z'
						fill='#5A5A5F'
					/>
					<path
						d='M167.77 90.8365C167.417 90.8236 167.067 90.8914 166.744 91.0345C166.422 91.1776 166.137 91.3924 165.91 91.6625V90.8898H164.173V98.5581H165.931V95.8936C166.158 96.1637 166.444 96.3785 166.766 96.5216C167.088 96.6647 167.439 96.7325 167.791 96.7196C169.225 96.7196 170.344 95.6112 170.344 93.7727C170.322 91.833 169.203 90.8365 167.77 90.8365ZM167.237 95.3501C166.39 95.3501 165.91 94.6999 165.91 93.7834C165.91 92.8668 166.39 92.2167 167.237 92.2167C168.084 92.2167 168.564 92.8775 168.564 93.7834C168.564 94.6893 168.09 95.3501 167.237 95.3501Z'
						fill='#5A5A5F'
					/>
					<path
						d='M243.397 90.8363C242.818 90.852 242.257 91.0378 241.783 91.3705C241.31 91.7033 240.944 92.1682 240.733 92.7072C240.522 93.2463 240.474 93.8356 240.596 94.4016C240.718 94.9676 241.004 95.4852 241.418 95.8898C241.832 96.2944 242.356 96.5681 242.924 96.6767C243.493 96.7852 244.081 96.7239 244.615 96.5003C245.149 96.2767 245.606 95.9008 245.927 95.4195C246.249 94.9382 246.422 94.3727 246.424 93.7938C246.436 93.3961 246.366 93.0002 246.217 92.6312C246.069 92.2621 245.845 91.928 245.56 91.6499C245.276 91.3719 244.937 91.156 244.564 91.0159C244.192 90.8758 243.794 90.8147 243.397 90.8363ZM243.397 95.3498C242.545 95.3498 242.124 94.6997 242.124 93.7938C242.124 92.8879 242.545 92.1951 243.397 92.1951C244.25 92.1951 244.665 92.8559 244.665 93.7938C244.665 94.7317 244.25 95.3498 243.397 95.3498Z'
						fill='#5A5A5F'
					/>
					<path
						d='M173.84 90.8363C173.261 90.852 172.7 91.0378 172.226 91.3705C171.752 91.7033 171.387 92.1682 171.176 92.7072C170.965 93.2463 170.917 93.8356 171.039 94.4016C171.16 94.9676 171.446 95.4852 171.86 95.8898C172.274 96.2944 172.798 96.5681 173.367 96.6767C173.936 96.7852 174.524 96.7239 175.058 96.5003C175.592 96.2767 176.048 95.9008 176.37 95.4195C176.691 94.9382 176.864 94.3727 176.866 93.7938C176.879 93.3961 176.808 93.0002 176.66 92.6312C176.511 92.2621 176.287 91.928 176.003 91.6499C175.718 91.3719 175.379 91.156 175.007 91.0159C174.634 90.8758 174.237 90.8147 173.84 90.8363ZM173.84 95.3498C172.987 95.3498 172.571 94.6997 172.571 93.7938C172.571 92.8879 172.987 92.1951 173.84 92.1951C174.692 92.1951 175.108 92.8559 175.108 93.7938C175.108 94.7317 174.692 95.3498 173.84 95.3498Z'
						fill='#5A5A5F'
					/>
					<path
						d='M248.95 92.2166C249.169 92.2246 249.383 92.2757 249.582 92.3672C249.78 92.4587 249.959 92.5886 250.107 92.7495L251.098 91.7637C250.802 91.4568 250.445 91.2149 250.051 91.0534C249.656 90.892 249.233 90.8144 248.807 90.8258C248.002 90.8258 246.675 91.1988 246.675 92.4245C246.675 93.1119 247.117 93.5542 248.103 94.0231C248.865 94.3962 249.27 94.5987 249.27 94.9664C249.27 95.2008 249.089 95.3287 248.737 95.3287C248.201 95.2755 247.703 95.0248 247.341 94.6253L246.323 95.6431C246.659 95.9812 247.058 96.2495 247.498 96.4324C247.938 96.6153 248.41 96.7093 248.886 96.7089C250.085 96.7089 251.018 96.1334 251.018 95.0676C251.018 94.247 250.416 93.7727 249.51 93.3624C248.604 92.952 248.444 92.8295 248.444 92.5364C248.444 92.2433 248.748 92.2166 248.95 92.2166Z'
						fill='#5A5A5F'
					/>
					<path
						d='M259.714 91.6624C259.488 91.3923 259.202 91.1775 258.88 91.0344C258.558 90.8913 258.207 90.8235 257.854 90.8364C256.426 90.8364 255.302 91.8329 255.302 93.7833C255.301 94.1438 255.351 94.5026 255.451 94.849C255.295 95.0918 255.065 95.2785 254.796 95.3819L254.631 95.4246H254.513C254.012 95.4619 253.757 95.1048 253.757 94.3588V92.2752H254.86V90.8897H253.757V89.4136L252.003 89.9092V90.8897H251.38V92.2752H252.003V94.4867C251.95 95.1008 252.106 95.715 252.446 96.2292C252.64 96.4595 252.886 96.6404 253.164 96.7571C253.442 96.8738 253.744 96.923 254.044 96.9007C254.769 96.8828 255.462 96.6025 255.995 96.112L256.091 96.0321H256.123C256.35 96.2667 256.622 96.4525 256.924 96.5781C257.225 96.7036 257.549 96.7662 257.876 96.7621C258.228 96.775 258.579 96.7073 258.901 96.5641C259.223 96.421 259.509 96.2062 259.736 95.9361C259.736 97.1831 258.984 97.4762 258.238 97.4762C257.534 97.4479 256.842 97.287 256.197 97.0019L255.425 98.1263C256.408 98.6049 257.486 98.8563 258.579 98.8617C260.077 98.8617 261.494 98.1263 261.494 96.0054V90.9217H259.736L259.714 91.6624ZM258.387 95.35C257.535 95.35 257.06 94.6998 257.06 93.7833C257.06 92.8667 257.535 92.2166 258.387 92.2166C259.24 92.2166 259.714 92.8774 259.714 93.7833C259.714 94.6892 259.24 95.35 258.387 95.35Z'
						fill='#5A5A5F'
					/>
					<path
						d='M209.468 91.6625C209.241 91.3924 208.956 91.1776 208.634 91.0345C208.311 90.8914 207.961 90.8236 207.608 90.8365C206.18 90.8365 205.056 91.833 205.056 93.7834C205.056 95.6218 206.18 96.7303 207.608 96.7303C207.961 96.7431 208.311 96.6754 208.634 96.5323C208.956 96.3891 209.241 96.1744 209.468 95.9043C209.468 97.1512 208.717 97.4443 207.971 97.4443C207.266 97.416 206.574 97.2551 205.93 96.9701L205.157 98.0945C206.14 98.5731 207.218 98.8244 208.312 98.8298C209.809 98.8298 211.227 98.0945 211.227 95.9736V90.8898H209.468V91.6625ZM208.141 95.3501C207.288 95.3501 206.814 94.6999 206.814 93.7834C206.814 92.8668 207.288 92.2167 208.141 92.2167C208.994 92.2167 209.468 92.8775 209.468 93.7834C209.468 94.6893 208.988 95.3501 208.136 95.3501H208.141Z'
						fill='#5A5A5F'
					/>
					<path
						d='M265.422 90.8365C265.105 90.805 264.788 90.8897 264.529 91.0751C264.27 91.2604 264.088 91.5336 264.015 91.8437V90.8898H262.257V96.677H264.015V94.3376C264.015 92.9681 264.239 92.2061 265.236 92.2061C265.393 92.2065 265.549 92.2389 265.694 92.3011C265.838 92.3634 265.969 92.4543 266.078 92.5684L266.328 91.2202C266.216 91.0915 266.077 90.99 265.92 90.9235C265.763 90.857 265.592 90.8273 265.422 90.8365Z'
						fill='#5A5A5F'
					/>
					<path
						d='M193.578 91.8434V90.8895H191.819V96.6767H193.578V94.3373C193.578 92.9678 193.807 92.2058 194.804 92.2058C194.96 92.206 195.115 92.2383 195.259 92.3006C195.403 92.3629 195.533 92.4539 195.64 92.5681L195.891 91.2199C195.78 91.0889 195.641 90.9852 195.484 90.9168C195.327 90.8484 195.156 90.8172 194.985 90.8256C194.666 90.7944 194.348 90.8807 194.089 91.0682C193.829 91.2556 193.648 91.5313 193.578 91.8434Z'
						fill='#5A5A5F'
					/>
					<path
						d='M269.238 90.8366C268.659 90.8533 268.098 91.04 267.625 91.3734C267.152 91.7067 266.788 92.172 266.578 92.7111C266.367 93.2502 266.32 93.8393 266.443 94.4049C266.565 94.9706 266.851 95.4877 267.265 95.8918C267.679 96.2958 268.204 96.569 268.772 96.6772C269.341 96.7853 269.928 96.7237 270.462 96.5C270.996 96.2763 271.452 95.9004 271.773 95.4192C272.095 94.938 272.267 94.3728 272.27 93.7941C272.282 93.396 272.212 92.9996 272.063 92.6301C271.914 92.2607 271.69 91.9263 271.404 91.6482C271.119 91.37 270.779 91.1542 270.406 91.0145C270.033 90.8748 269.635 90.8142 269.238 90.8366ZM269.238 95.3501C268.39 95.3501 267.969 94.7 267.969 93.7941C267.969 92.8882 268.39 92.1954 269.238 92.1954C270.085 92.1954 270.511 92.8562 270.511 93.7941C270.511 94.732 270.09 95.3501 269.238 95.3501Z'
						fill='#5A5A5F'
					/>
					<path
						d='M282.475 90.8362C282.122 90.8225 281.771 90.8899 281.449 91.0331C281.126 91.1763 280.841 91.3915 280.615 91.6622V90.8895H278.856V98.5578H280.615V95.8933C280.841 96.164 281.126 96.3792 281.449 96.5224C281.771 96.6656 282.122 96.733 282.475 96.7193C283.903 96.7193 285.022 95.6109 285.022 93.7724C284.995 91.8327 283.876 90.8362 282.475 90.8362ZM281.942 95.3498C281.089 95.3498 280.615 94.6997 280.615 93.7831C280.615 92.8665 281.089 92.2164 281.942 92.2164C282.794 92.2164 283.269 92.8772 283.269 93.7831C283.269 94.689 282.763 95.3498 281.942 95.3498Z'
						fill='#5A5A5F'
					/>
					<path
						d='M216.14 94.2147C216.121 93.8433 216.182 93.4722 216.319 93.1265C216.456 92.7809 216.666 92.4686 216.934 92.211C217.221 91.949 217.557 91.7468 217.923 91.6159C218.289 91.4851 218.677 91.4284 219.065 91.449V89.7278C217.849 89.6903 216.668 90.1347 215.777 90.9641C214.923 91.8314 214.444 93 214.444 94.2174C214.444 95.4348 214.923 96.6033 215.777 97.4707C216.669 98.2966 217.85 98.7388 219.065 98.7016V96.9857C218.677 97.0066 218.288 96.9494 217.922 96.8176C217.556 96.6858 217.22 96.4821 216.934 96.2184C216.666 95.9608 216.456 95.6486 216.319 95.3029C216.182 94.9572 216.121 94.5861 216.14 94.2147Z'
						fill='#5A5A5F'
					/>
					<path
						d='M219.061 86.0617V87.7829C219.449 87.7629 219.837 87.8199 220.203 87.9507C220.568 88.0815 220.905 88.2835 221.192 88.545C221.699 89.0893 221.98 89.8052 221.98 90.5486C221.98 91.2921 221.699 92.008 221.192 92.5523C220.906 92.8157 220.57 93.0193 220.204 93.1511C219.838 93.2829 219.449 93.3402 219.061 93.3196V95.0356C220.276 95.0727 221.457 94.6306 222.348 93.8046C223.203 92.9372 223.682 91.7687 223.682 90.5513C223.682 89.3339 223.203 88.1653 222.348 87.298C221.458 86.4691 220.277 86.0248 219.061 86.0617Z'
						fill='#5A5A5F'
					/>
					<path
						d='M183.298 94.9875L182.211 90.8843H180.357L179.296 94.9769L178.545 90.8843H176.808L178.262 96.5649H180.202L181.241 92.7547L182.27 96.5649H184.215L185.67 90.8843H184.05L183.298 94.9875Z'
						fill='#5A5A5F'
					/>
					<path
						d='M190.013 91.1561C189.551 90.9266 189.042 90.8071 188.526 90.8071C188.011 90.8071 187.502 90.9266 187.04 91.1561C186.611 91.3857 186.259 91.7359 186.027 92.1633C185.778 92.6435 185.656 93.1788 185.67 93.7193C185.656 94.257 185.781 94.7891 186.032 95.2647C186.264 95.6936 186.616 96.0454 187.045 96.2772C187.473 96.4941 187.944 96.6137 188.424 96.6275C188.904 96.6413 189.381 96.549 189.821 96.3571C190.192 96.1865 190.518 95.9331 190.775 95.6164C191.023 95.3067 191.192 94.9407 191.265 94.5507H189.422C189.371 94.7512 189.252 94.9281 189.086 95.0516C188.9 95.1845 188.675 95.2519 188.446 95.2434C188.169 95.2522 187.9 95.1528 187.695 94.9663C187.471 94.7127 187.353 94.3827 187.365 94.0444H191.303C191.337 93.8905 191.355 93.7332 191.356 93.5755C191.366 93.0734 191.245 92.5774 191.004 92.1367C190.775 91.7211 190.431 91.3807 190.013 91.1561ZM187.375 93.2877C187.381 92.9745 187.509 92.6759 187.732 92.4564C187.95 92.2619 188.235 92.1589 188.526 92.1686C188.809 92.1604 189.084 92.2616 189.294 92.4511C189.402 92.5604 189.485 92.6918 189.537 92.8362C189.59 92.9806 189.61 93.1346 189.597 93.2877H187.375Z'
						fill='#5A5A5F'
					/>
					<path d='M197.99 90.8843H196.247V96.5649H197.99V90.8843Z' fill='#5A5A5F' />
					<path
						d='M197.121 88.3692C196.844 88.3546 196.573 88.4482 196.364 88.6303C196.275 88.7135 196.204 88.8142 196.156 88.926C196.107 89.0379 196.082 89.1585 196.082 89.2804C196.082 89.4023 196.107 89.5229 196.156 89.6348C196.204 89.7466 196.275 89.8473 196.364 89.9305C196.573 90.1126 196.844 90.2062 197.121 90.1916C197.396 90.2048 197.665 90.1113 197.872 89.9305C197.963 89.8481 198.035 89.7477 198.085 89.6358C198.134 89.5238 198.16 89.4028 198.16 89.2804C198.16 89.158 198.134 89.037 198.085 88.925C198.035 88.8131 197.963 88.7127 197.872 88.6303C197.665 88.4495 197.396 88.356 197.121 88.3692Z'
						fill='#5A5A5F'
					/>
					<path
						d='M202.344 90.8202C201.945 90.8115 201.551 90.9149 201.208 91.1187C200.891 91.3087 200.637 91.5888 200.478 91.9233V90.8842H198.741V96.5595H200.478V93.5486C200.457 93.203 200.568 92.8622 200.788 92.5948C200.896 92.4821 201.027 92.3938 201.172 92.3359C201.317 92.278 201.473 92.2518 201.629 92.259C201.778 92.2538 201.925 92.2796 202.063 92.3346C202.2 92.3897 202.325 92.4728 202.429 92.5788C202.641 92.8249 202.748 93.1444 202.727 93.4687V96.5595H204.47V93.2662C204.508 92.6185 204.303 91.9798 203.894 91.4757C203.701 91.2582 203.461 91.0865 203.193 90.9731C202.925 90.8597 202.634 90.8075 202.344 90.8202Z'
						fill='#5A5A5F'
					/>
					<path d='M72.9316 75.3717V54.4878H77.4559V75.3717H72.9316Z' fill='#5A5A5F' />
					<path
						d='M93.3256 74.6842C92.4625 75.052 91.5566 75.3098 90.6292 75.4515C89.3665 75.6601 88.0881 75.7581 86.8084 75.7446C84.3784 75.7446 82.5612 75.3556 81.3569 74.5776C80.7805 74.2264 80.3086 73.7275 79.99 73.1325C79.6714 72.5375 79.5178 71.8681 79.5451 71.1937C79.5115 70.338 79.7856 69.4986 80.3178 68.8277C80.8838 68.1699 81.5929 67.6504 82.3907 67.309C83.3662 66.8877 84.39 66.5888 85.4388 66.4191C86.6005 66.2166 87.8209 66.062 89.0998 65.9554V65.236C89.1337 64.9098 89.0709 64.5808 88.9193 64.2899C88.7677 63.9991 88.534 63.7592 88.2472 63.6001C87.5285 63.2752 86.7436 63.1237 85.9557 63.1578C85.1514 63.1588 84.3494 63.2445 83.5631 63.4136C82.8716 63.5515 82.1939 63.7513 81.5381 64.0104L80.5842 61.7243C81.3905 61.4309 82.2168 61.1958 83.0568 61.0209C84.2833 60.7647 85.5342 60.6432 86.787 60.6585C87.6636 60.652 88.538 60.7468 89.3929 60.9409C90.1335 61.1006 90.8347 61.4068 91.4552 61.8415C92.0368 62.2656 92.5038 62.8279 92.814 63.4775C93.162 64.2466 93.3297 65.085 93.3043 65.9288L93.3256 74.6842ZM89.0998 67.7566C88.3164 67.8312 87.597 67.9271 86.9416 68.0497C86.3613 68.1466 85.7978 68.326 85.2683 68.5826C84.8388 68.8014 84.472 69.126 84.2025 69.5258C83.9315 69.973 83.7981 70.4901 83.8189 71.0126C83.7879 71.3772 83.8527 71.7435 84.0069 72.0754C84.1611 72.4072 84.3994 72.693 84.6981 72.9043C85.4206 73.3309 86.2529 73.5348 87.0908 73.4905C87.4842 73.4944 87.8769 73.4587 88.2632 73.3839C88.5563 73.3269 88.8423 73.2376 89.1158 73.1175L89.0998 67.7566Z'
						fill='#5A5A5F'
					/>
					<path
						d='M105.651 75.3717V65.2895C105.674 64.9685 105.608 64.6474 105.462 64.3609C105.315 64.0744 105.093 63.8335 104.819 63.6642C104.186 63.3087 103.467 63.1336 102.741 63.1579C102.35 63.1569 101.96 63.189 101.574 63.2538C101.261 63.304 100.953 63.3789 100.652 63.4777V75.3717H96.1279V61.7244C97.0878 61.3798 98.078 61.1265 99.0855 60.9677C100.344 60.7538 101.619 60.6504 102.896 60.6587C105.262 60.6587 107.066 61.0264 108.31 61.7617C108.914 62.1088 109.409 62.6183 109.739 63.2324C110.069 63.8465 110.22 64.5406 110.175 65.2362V75.3611L105.651 75.3717Z'
						fill='#5A5A5F'
					/>
					<path
						d='M126.908 74.6843C126.053 75.048 125.159 75.3109 124.244 75.4677C123.027 75.6688 121.795 75.7616 120.561 75.7448C117.972 75.7448 115.957 75.1248 114.519 73.885C113.08 72.6451 112.369 70.7907 112.387 68.3216C112.369 67.2514 112.55 66.1872 112.92 65.1829C113.249 64.2902 113.766 63.4788 114.436 62.8038C115.106 62.1289 115.914 61.6063 116.805 61.2715C117.77 60.9066 118.794 60.7258 119.826 60.7386C120.328 60.736 120.83 60.7627 121.329 60.8185C121.709 60.8602 122.086 60.9225 122.459 61.005V54.4878H126.929L126.908 74.6843ZM122.437 63.1579C121.922 63.05 121.397 62.9982 120.871 63.0034C120.253 62.9864 119.641 63.118 119.085 63.387C118.597 63.6361 118.178 64.0022 117.865 64.4528C117.529 64.9359 117.289 65.4784 117.156 66.0515C117.002 66.7279 116.927 67.42 116.932 68.1138C116.932 69.7657 117.226 71.018 117.812 71.8813C118.398 72.7446 119.341 73.1709 120.657 73.1709C121.001 73.1737 121.344 73.1416 121.681 73.075C121.941 73.0316 122.195 72.9564 122.437 72.8512V63.1579Z'
						fill='#5A5A5F'
					/>
					<path
						d='M148.719 75.3718V65.2469C148.729 64.9722 148.681 64.6985 148.58 64.4428C148.48 64.187 148.327 63.9547 148.133 63.7602C147.586 63.3046 146.881 63.0842 146.172 63.1474C145.301 63.1263 144.449 63.4089 143.763 63.9467V75.3612H139.239V65.2096C139.25 64.924 139.191 64.64 139.066 64.3826C138.942 64.1253 138.756 63.9024 138.525 63.7335C137.914 63.3364 137.19 63.1493 136.463 63.2006C136.047 63.1943 135.632 63.2246 135.221 63.2912C134.899 63.3473 134.581 63.422 134.267 63.515V75.3825H129.743V61.7245C130.712 61.3718 131.711 61.1096 132.727 60.9412C133.952 60.7426 135.191 60.6481 136.431 60.6587C137.556 60.6203 138.68 60.7697 139.756 61.101C140.471 61.3375 141.138 61.6983 141.728 62.1668C142.397 61.7016 143.134 61.3421 143.913 61.101C144.993 60.7803 146.117 60.631 147.243 60.6587C148.137 60.6405 149.028 60.7664 149.881 61.0318C150.562 61.2469 151.197 61.5847 151.757 62.0283C152.244 62.4223 152.632 62.924 152.892 63.4937C153.136 64.0422 153.263 64.6358 153.265 65.2363V75.3612L148.719 75.3718Z'
						fill='#5A5A5F'
					/>
					<path
						d='M169.007 74.6841C168.142 75.0513 167.234 75.3091 166.305 75.4515C165.042 75.66 163.764 75.7581 162.484 75.7446C160.054 75.7446 158.237 75.3556 157.033 74.5776C156.456 74.2264 155.984 73.7274 155.666 73.1324C155.347 72.5374 155.194 71.8681 155.221 71.1937C155.19 70.3383 155.463 69.4997 155.994 68.8277C156.562 68.1698 157.272 67.6503 158.072 67.3089C159.045 66.8877 160.067 66.5887 161.115 66.419C162.276 66.2165 163.497 66.062 164.776 65.9554V65.236C164.811 64.9096 164.749 64.58 164.597 64.2889C164.445 63.9978 164.211 63.7581 163.923 63.6C163.206 63.2756 162.423 63.1241 161.637 63.1577C160.831 63.1584 160.027 63.2442 159.239 63.4135C158.549 63.5515 157.873 63.7512 157.219 64.0104L156.26 61.7242C157.066 61.4315 157.893 61.1965 158.733 61.0208C159.959 60.7649 161.21 60.6434 162.463 60.6585C163.341 60.6523 164.217 60.7471 165.074 60.9409C165.814 61.1017 166.515 61.4078 167.136 61.8415C167.716 62.2659 168.182 62.8282 168.49 63.4775C168.84 64.2461 169.009 65.0846 168.985 65.9288L169.007 74.6841ZM164.776 67.7566C163.992 67.8312 163.278 67.9271 162.617 68.0497C162.037 68.1465 161.474 68.326 160.944 68.5825C160.513 68.7994 160.146 69.1244 159.878 69.5258C159.603 69.9712 159.467 70.4892 159.489 71.0125C159.458 71.3771 159.523 71.7435 159.677 72.0753C159.832 72.4072 160.07 72.6929 160.369 72.9043C161.091 73.3309 161.923 73.5348 162.761 73.4905C163.155 73.4946 163.547 73.4589 163.934 73.3839C164.227 73.3277 164.513 73.2383 164.786 73.1174L164.776 67.7566Z'
						fill='#5A5A5F'
					/>
					<path
						d='M180.027 63.5576C179.379 63.3549 178.706 63.2437 178.028 63.2272C177.444 63.1884 176.858 63.2775 176.312 63.4883V75.3558H171.788V61.7244C172.734 61.3595 173.717 61.0969 174.719 60.9411C176.073 60.7362 177.442 60.6418 178.812 60.6587C179.169 60.6587 179.542 60.6587 179.931 60.6587C180.32 60.6587 180.629 60.7013 180.863 60.7386L180.027 63.5576Z'
						fill='#5A5A5F'
					/>
					<path
						d='M192.24 75.3717L186.863 67.7354H186.81V75.3717H182.339V54.4878H186.81V67.2292H186.863L192.027 61.0317H196.679L190.961 67.2292L197.356 75.3717H192.24Z'
						fill='#5A5A5F'
					/>
					<path
						d='M220.548 75.5318C220.569 76.4438 220.374 77.3478 219.978 78.1696C219.598 78.9032 219.05 79.5367 218.379 80.0187C217.657 80.5249 216.85 80.8984 215.997 81.1218C215.019 81.3765 214.012 81.5019 213.002 81.4948C211.751 81.5094 210.501 81.3969 209.272 81.1591C208.378 80.996 207.5 80.7516 206.65 80.429L207.796 77.8498C208.441 78.1182 209.113 78.3182 209.799 78.4467C210.627 78.6172 211.47 78.7029 212.315 78.7025C213.647 78.7025 214.601 78.4413 215.176 77.9298C215.463 77.6673 215.689 77.3453 215.838 76.9862C215.987 76.6272 216.056 76.2399 216.04 75.8515V75.0522C215.642 75.2254 215.222 75.3419 214.793 75.3985C214.167 75.4951 213.534 75.5397 212.901 75.5318C211.948 75.5438 211.001 75.3775 210.109 75.0415C209.283 74.735 208.531 74.2562 207.905 73.6369C207.278 73.0176 206.79 72.2721 206.474 71.4498C206.111 70.5214 205.93 69.5318 205.941 68.5349C205.926 67.4337 206.106 66.3384 206.474 65.3003C206.808 64.3599 207.355 63.5098 208.073 62.817C208.835 62.0976 209.744 61.5522 210.737 61.2183C211.955 60.8326 213.228 60.6525 214.505 60.6855C215.674 60.6765 216.841 60.78 217.99 60.9945C218.845 61.1304 219.678 61.3851 220.463 61.7512L220.548 75.5318ZM216.077 63.3446C215.846 63.2458 215.607 63.1673 215.363 63.1101C215.022 63.0316 214.673 62.9958 214.324 63.0035C213.777 62.9689 213.231 63.0752 212.737 63.3122C212.244 63.5492 211.819 63.909 211.505 64.3571C210.879 65.263 210.569 66.5845 210.572 68.3218C210.572 71.6186 211.787 73.2688 214.217 73.2723C214.566 73.2726 214.914 73.2405 215.256 73.1764C215.54 73.1327 215.817 73.0484 216.077 72.9259V63.3446Z'
						fill='#5A5A5F'
					/>
					<path d='M223.409 75.3717V54.4878H227.933V75.3717H223.409Z' fill='#5A5A5F' />
					<path
						d='M245.364 68.1886C245.375 69.2191 245.213 70.2441 244.884 71.2208C244.589 72.1111 244.099 72.9247 243.451 73.6028C242.781 74.289 241.973 74.8249 241.08 75.1748C238.964 75.9351 236.651 75.9351 234.536 75.1748C233.644 74.8248 232.838 74.2889 232.17 73.6028C231.518 72.9241 231.024 72.111 230.72 71.2208C230.059 69.2624 230.059 67.1414 230.72 65.1831C231.027 64.2914 231.522 63.4756 232.17 62.7904C232.829 62.0878 233.638 61.5416 234.536 61.1918C236.653 60.4458 238.962 60.4458 241.08 61.1918C241.969 61.5283 242.771 62.0588 243.43 62.7446C244.088 63.4304 244.585 64.2544 244.884 65.1565C245.215 66.1328 245.377 67.158 245.364 68.1886ZM240.786 68.162C240.788 67.5303 240.74 66.8996 240.643 66.2755C240.565 65.7212 240.404 65.1821 240.163 64.6769C239.957 64.2502 239.645 63.8834 239.257 63.6111C238.819 63.3497 238.318 63.2117 237.808 63.2117C237.297 63.2117 236.796 63.3497 236.358 63.6111C235.968 63.8843 235.653 64.2507 235.442 64.6769C235.195 65.1812 235.028 65.7204 234.946 66.2755C234.848 66.8996 234.8 67.5303 234.802 68.162C234.8 68.7865 234.848 69.4103 234.946 70.0271C235.031 70.5628 235.198 71.0823 235.442 71.5671C235.653 71.9986 235.975 72.3663 236.374 72.6329C236.813 72.8844 237.31 73.0167 237.816 73.0167C238.321 73.0167 238.818 72.8844 239.257 72.6329C239.647 72.3633 239.96 71.9958 240.163 71.5671C240.401 71.0814 240.563 70.562 240.643 70.0271C240.741 69.4103 240.789 68.7865 240.786 68.162Z'
						fill='#5A5A5F'
					/>
					<path
						d='M262.145 67.8154C262.145 70.3022 261.408 72.2561 259.934 73.6771C258.459 75.0982 256.34 75.7945 253.576 75.7661C252.359 75.7837 251.144 75.6819 249.947 75.4623C249.131 75.3207 248.338 75.066 247.592 74.7056V54.4878H252.057V61.2715C252.51 61.1109 252.973 60.9827 253.443 60.8878C254.083 60.7602 254.735 60.7012 255.388 60.7119C256.327 60.7088 257.258 60.8899 258.127 61.2448C258.931 61.5765 259.657 62.0719 260.259 62.6996C260.866 63.3429 261.337 64.1024 261.644 64.9324C261.982 65.8558 262.151 66.8322 262.145 67.8154ZM257.567 68.0552C257.571 67.3899 257.508 66.7259 257.381 66.0728C257.276 65.5071 257.068 64.9653 256.768 64.4741C256.508 64.0351 256.141 63.6686 255.702 63.4084C255.21 63.1507 254.659 63.0241 254.104 63.0407C253.412 63.0142 252.723 63.1303 252.079 63.3817V72.9737C252.309 73.0734 252.551 73.145 252.798 73.1869C253.15 73.2465 253.507 73.2733 253.864 73.2668C254.419 73.2993 254.973 73.1832 255.469 72.9302C255.964 72.6773 256.383 72.2969 256.683 71.828C257.334 70.6815 257.641 69.3716 257.567 68.0552Z'
						fill='#5A5A5F'
					/>
					<path
						d='M277.327 74.6842C276.462 75.0514 275.554 75.3092 274.625 75.4516C273.364 75.6599 272.087 75.758 270.81 75.7447C268.38 75.7447 266.561 75.3557 265.353 74.5776C264.777 74.2256 264.306 73.7265 263.987 73.1317C263.669 72.5369 263.515 71.868 263.541 71.1938C263.51 70.3384 263.784 69.4998 264.314 68.8278C264.883 68.171 265.593 67.6517 266.392 67.309C267.365 66.8878 268.388 66.5888 269.435 66.4191C270.596 66.2166 271.817 66.0621 273.096 65.9555V65.2361C273.131 64.9097 273.069 64.5801 272.917 64.289C272.765 63.9979 272.531 63.7582 272.243 63.6001C271.526 63.2748 270.743 63.1232 269.957 63.1578C269.151 63.1585 268.347 63.2443 267.559 63.4136C266.869 63.5516 266.193 63.7513 265.539 64.0104L264.58 61.7243C265.388 61.431 266.216 61.196 267.058 61.0209C268.284 60.7645 269.535 60.643 270.788 60.6586C271.665 60.6527 272.539 60.7475 273.394 60.941C274.135 61.1007 274.836 61.4069 275.456 61.8416C276.037 62.2673 276.503 62.8291 276.815 63.4775C277.161 64.2474 277.328 65.0853 277.305 65.9288L277.327 74.6842ZM273.096 67.7567C272.312 67.8313 271.598 67.9272 270.964 68.0497C270.382 68.1467 269.817 68.3261 269.285 68.5826C268.855 68.7995 268.487 69.1245 268.22 69.5258C267.944 69.9713 267.809 70.4893 267.831 71.0126C267.8 71.3772 267.865 71.7436 268.019 72.0754C268.173 72.4072 268.411 72.693 268.71 72.9044C269.435 73.3305 270.268 73.5343 271.108 73.4905C271.5 73.4944 271.891 73.4587 272.275 73.384C272.568 73.3278 272.854 73.2384 273.128 73.1175L273.096 67.7567Z'
						fill='#5A5A5F'
					/>
					<path d='M280.178 75.3717V54.4878H284.702V75.3717H280.178Z' fill='#5A5A5F' />
					<path
						d='M107.303 0C92.5789 32.2451 58.037 65.3269 30.2095 73.7679C2.38206 82.2089 -8.1585 62.7531 6.82636 30.3481L16.493 27.4225C6.69313 48.6422 13.5461 61.3942 31.8189 55.8575C50.0917 50.3208 72.6862 28.6801 82.3901 7.51907L107.303 0Z'
						fill='url(#odfzuwlmom-paint0_linear_106571_960)'
					/>
					<path
						d='M20.8575 26.1008C15.8644 36.9238 19.3495 43.4144 28.659 40.6007C37.9686 37.787 49.527 26.7456 54.4829 15.9492L20.8575 26.1008Z'
						fill='url(#odfzuwlmom-paint1_linear_106571_960)'
					/>
				</g>
				<defs>
					<linearGradient
						id='odfzuwlmom-paint0_linear_106571_960'
						x1='1.55608'
						y1='66.3075'
						x2='103.668'
						y2='-5.19034'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#970000' />
						<stop offset='0.11' stopColor='#A6070A' />
						<stop offset='0.41' stopColor='#CE1822' />
						<stop offset='0.65' stopColor='#E62231' />
						<stop offset='0.8' stopColor='#EF2637' />
					</linearGradient>
					<linearGradient
						id='odfzuwlmom-paint1_linear_106571_960'
						x1='19.1043'
						y1='38.1121'
						x2='53.2572'
						y2='14.196'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#970000' />
						<stop offset='0.11' stopColor='#A6070A' />
						<stop offset='0.41' stopColor='#CE1822' />
						<stop offset='0.65' stopColor='#E62231' />
						<stop offset='0.8' stopColor='#EF2637' />
					</linearGradient>
					<clipPath id='odfzuwlmom-clip0_106571_960'>
						<rect width='285' height='98.835' fill='white' />
					</clipPath>
				</defs>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Landmark;
