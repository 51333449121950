import { getAsyncError } from '@services/handle-fetch-response';
import { OrderDetailPOMS } from '@ts/poms/orders';

export const getOrderDetails = async ({ id }): Promise<OrderDetailPOMS> => {
	try {
		const pomsUrl = `${process.env.NEXT_PUBLIC_POMS_URL}/api/v2/order/${id}`;

		const res = await fetch(pomsUrl, {
			method: 'GET',
			headers: {
				'X-API-KEY': process.env.NEXT_PUBLIC_POMS_API_KEY,
			},
		});

		if (res.ok) {
			return await res.json();
		}

		throw await getAsyncError(res);
	} catch (error) {
		throw error;
	}
};
