/* eslint-disable max-lines */
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { RxUploadIssue } from '@services/poms/operations/rx-data-upload';
import { RXSS_FIELD_OPTIONS } from '@utils/constants/rxss';
import { isNullish } from '@utils/objects';
import { getBasePropertyName } from '@utils/rxss';
import { capitalize } from '@utils/strings';

export type RxValue = {
	/** This is oculus sinister, meaning left eye. */
	OS: number;
	/** This is oculus dexter, meaning right eye. */
	OD: number;
};

export type RxForm = {
	sphere: RxValue;
	cylinder: {
		cyl: RxValue;
		axis: RxValue;
	};
	add: RxValue;
	prism: {
		horizontal: {
			prism: RxValue;
			base: { OS: HorizontalBaseValue; OD: HorizontalBaseValue };
		};
		vertical: {
			prism: RxValue;
			base: { OS: VerticalBaseValue; OD: VerticalBaseValue };
		};
	};
	pd: RxValue;
	shopify_product_id: number;
};

export type RxssPomsPayload = {
	shopify_product_id?: number | string;
	left_prism_horizontal_base?: HorizontalBaseValue;
	left_prism_vertical_base?: VerticalBaseValue;
	right_prism_horizontal_base?: HorizontalBaseValue;
	right_prism_vertical_base?: VerticalBaseValue;
	left_sphere?: number;
	right_sphere?: number;
	left_cylinder?: number;
	right_cylinder?: number;
	left_prism_horizontal_prism?: number;
	left_prism_vertical_prism?: number;
	right_prism_horizontal_prism?: number;
	right_prism_vertical_prism?: number;
	left_add?: number;
	right_add?: number;
	left_pd?: number;
	right_pd?: number;
	left_axis?: number;
	right_axis?: number;
	single_pd?: number;
};

export type HorizontalBaseValue = typeof HORIZONTAL_BASE_VALUES[number];
export type VerticalBaseValue = typeof VERTICAL_BASE_VALUES[number];

// Constants
export const HORIZONTAL_BASE_VALUES = ['BI', 'BO'] as const;
export const VERTICAL_BASE_VALUES = ['BU', 'BD'] as const;

// Helpers


export function getLogFromAttribute(logs: PrescriptionLog[], attribute: PomsRXAttributes): string {
	const log = logs.find(log => log.field === attribute);
	return log?.message ?? null;
}

/** Remove all fields from the form that are related to the field that was deselected */
export const bulkResetRxFields = (field: keyof RxForm, setValue: UseFormSetValue<FieldValues>) => {
	switch (field) {
		case 'sphere':
			setValue('left_sphere', null);
			setValue('right_sphere', null);
			break;
		case 'cylinder':
			setValue('left_cylinder', null);
			setValue('right_cylinder', null);
			setValue('left_axis', null);
			setValue('right_axis', null);
			break;
		case 'add':
			setValue('left_add', null);
			setValue('right_add', null);
			break;
		case 'prism':
			setValue('left_prism_horizontal_base', null);
			setValue('left_prism_vertical_base', null);
			setValue('right_prism_horizontal_base', null);
			setValue('right_prism_vertical_base', null);
			setValue('left_prism_horizontal_prism', null);
			setValue('left_prism_vertical_prism', null);
			setValue('right_prism_horizontal_prism', null);
			setValue('right_prism_vertical_prism', null);
			break;
		case 'pd':
			setValue('left_pd', null);
			setValue('right_pd', null);
			setValue('single_pd', null);
			break;
		default:
			break;
	}
};

export type PrescriptionTableColumn = {
	option: { title?: string; subtitle?: string };
	left: string | number;
	right: string | number;
	warning?: string;
	error?: string;
};

export type PrescriptionLog = {
	field: string;
	message: string;
};

export const transformPrescription = ({
	formData,
	hasTwoPds,
	selectedFields,
	warnings,
	errors,
}: {
	formData: Partial<RxssPomsPayload>;
	hasTwoPds: boolean;
	selectedFields: string[];
	warnings: RxUploadIssue[];
	errors: RxUploadIssue[];
}): PrescriptionTableColumn[] => {
	if (!formData || isNullish(formData)) {
		return null;
	}

	const generateWarningsAndError = (left: PomsRXAttributes, right: PomsRXAttributes): { warning: string; error: string } => {
		return {
			warning: getLogFromAttribute(warnings, left) || getLogFromAttribute(warnings, right),
			error: getLogFromAttribute(errors, left) || getLogFromAttribute(errors, right),
		};
	};

	return selectedFields.reduce((acc, curr) => {
		const value = RXSS_FIELD_OPTIONS[curr];
		if (!value) return acc;

		value.measurements.forEach(({ title, subtitle, inputs }) => {
			// eslint-disable-next-line prefer-const
			const [left, right, alt] = inputs;
			const leftData =
				curr === 'prism' ? `${formData[left.id]} ∆ ${formData[getBasePropertyName(left.id)]}` : formData[left.id];
			const rightData =
				curr === 'prism' ? `${formData[right.id]} ∆ ${formData[getBasePropertyName(right.id)]}` : formData[right.id];
			if (!hasTwoPds && alt) return;

			acc.push({
				option: {
					title,
					subtitle,
				},
				left: leftData,
				right: rightData,
				...generateWarningsAndError(left.id, right.id),
			});
		});

		return acc;
	}, []);
};

export const getWarningsFieldsAsString = (warnings: RxUploadIssue[]) => {
	if (!warnings) return null;
	const fields = warnings.map(warning => warning.field.replace('right_', '').replace('left_', ''))
		.filter((field, index, self) => self.indexOf(field) === index);
	return { fields, textFields: fields.map(field => `The ${capitalize(field)}`).join(', ') };
};

export type PomsRXAttributes = keyof RxssPomsPayload;
